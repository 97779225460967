export const columns = [
  {
    field: "semester",
    label: "学期",
    align: "left",
    width: "120,",
  },
  {
    field: "level",
    label: "年级",
    align: "left",
    width: "120,",
  },
  {
    field: "name",
    label: "名称",
    align: "left",
    width: "120,",
  },
  {
    field: "teacher",
    label: "班主任",
    align: "left",
    width: "120,",
  },
  {
    field: "mobile",
    label: "手机号",
    align: "left",
    width: "120",
  },
  // {
  //   field: "schedule",
  //   label: "课程表",
  //   align: "left",
  //   width: "80",
  // },
  {
    field: "teachers",
    label: "任课老师",
    align: "left",
    width: "auto,",
  },
  {
    field: "count",
    label: "学生人数",
    align: "center",
    width: "80",
  },
  {
    field: "action",
    label: "操作",
    align: "center",
    width: "180",
  },
];

export const searchOptions = [
  {
    type: "input",
    field: "name",
    label: "名称",
    placeholder: "按班级名称查询",
  },
  {
    type: "input",
    field: "level",
    label: "年级",
    placeholder: "按年级查询",
  },
  {
    type: "input",
    field: "semester",
    label: "学期",
    placeholder: "按学期查询",
  },
  {
    type: "input",
    field: "teacherId",
    label: "班主任",
    placeholder: "按班主任查询",
  },
];

//  学生表格列表
export const stadentColumns = [
  {
    field: "no",
    label: "学号",
    align: "left",
    width: "100px",
  },
  {
    field: "username",
    label: "姓名",
    align: "left",
    width: "auto,",
  },
];

// 课程列表
export const scheduleColumns = [
  {
    field: "subjectName",
    label: "科目",
    align: "left",
    width: "100px",
  },
  {
    field: "teacherName",
    label: "教师",
    align: "left",
    width: "auto,",
  },
  {
    field: "action",
    label: "操作",
    align: "center",
    width: "180",
  }
]
