export const columns = [
  {
    field: "name",
    label: "课程名称",
    align: "left",
    width: "200",
  },
  {
    field: "action",
    label: "操作",
    align: "center",
    width: "100",
  },
];

export const searchOptions = [
  {
    type: "input",
    field: "name",
    label: "",
    placeholder: "按课程表名称",
  },
];
