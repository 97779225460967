<template>
  <div v-loading="loading">
    <Main
      class="h-full"
      ref="main"
      :on-params="onParams"
      :columns="columns"
      :is-selection="false"
      api="/room/list"
      @onSelectionChange="handleOnSelectionChange"
    >
      <template #search>
        <Search
          v-model="searchForm"
          :options="searchOptions"
          @onSearch="onFind()"
        ></Search>
      </template>
      <template #action>
        <el-button @click="handleExportTempalte">学生导入模板</el-button>
        <el-button type="success" @click="handleLitreGrade"
          >一键提升班级</el-button
        >

        <el-dropdown
          class="ml-1"
          split-button
          type="primary"
          @command="handleCreate"
          @click="handleCreateClass"
        >
          新增班级
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="batch">批量新增</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <template #table-item-semester="{ row }">
        <template v-if="row.semester"> 第{{ row.semester }}学期 </template>
        <template v-else>-</template>
      </template>
      <template #table-item-level="{ row }">
        <template v-if="row.level"> {{ row.level }}年级 </template>
        <template v-else>-</template>
      </template>
      <template #table-item-name="{ row }">
        <template v-if="row.level && row.name">
          {{ row.level
          }}{{ row.name && row.name.length > 1 ? row.name : "0" + row.name }} 班
        </template>
        <template v-else>-</template>
      </template>
      <template #table-item-schedule="{ row }">
        <template v-if="row.schedule && row.name">
          {{ row.schedule.name }}
        </template>
        <template v-else>-</template>
      </template>

      <template #table-item-teacher="{ row }">
        <template v-if="row.user && row.user.username">
          {{ row.user.username }}
        </template>
        <template v-else>-</template>
      </template>
      <template #table-item-mobile="{ row }">
        <template v-if="row.user && row.user.mobile">
          {{ row.user.mobile }}
        </template>
        <template v-else>-</template>
      </template>
      <template #table-item-teachers="{ row }">
        {{ onTaechers(row["teachers"]) }}
      </template>

      <template #table-item-action="{ row }">
        <el-button type="text" @click="handleSchedule(row)">课程表</el-button>
        <el-button type="text" @click="handleUpdateClass(row)">修改</el-button>
        <el-button
          type="text"
          class="text-red-600"
          @click="handleDeleteClass(row)"
          >删除</el-button
        >
        <el-upload
          action=""
          :show-file-list="false"
          style="display: contents"
          accept=".xls,.XLS,.xlsx,.XLSX"
          :http-request="handleImportStudent"
        >
          <el-button
            class="ml-1"
            size="mini"
            type="text"
            @click="handleSelectRow(row)"
            >导入学生</el-button
          >
        </el-upload>
      </template>
    </Main>
    <Edit ref="edit" @refresh="onRefresh"></Edit>
    <Schedule ref="schedule" @refresh="onRefresh"></Schedule>
    <BatchCreate ref="batchCreate" @refresh="onRefresh"></BatchCreate>
  </div>
</template>
<script>
import xlsx from "xlsx";
import Main from "../components/main/index.vue";
import Search from "../components/search/index.vue";
import { columns, searchOptions } from "./config";
import Edit from "./components/edit.vue";
import Schedule from "./components/schedule.vue";
import BatchCreate from "./components/batchCreate.vue";
export default {
  name: "Class",
  components: { Main, Search, Edit, Schedule, BatchCreate },
  props: {},
  data() {
    return {
      columns,
      searchOptions,
      searchForm: {},
      loading: false,

      row: {}, // 选择的行
    };
  },
  created() {},
  async mounted() {
    await this.onFind();
    this.$nextTick(async () => {
      await this.$refs["edit"].onLoading();
    });
  },
  methods: {
    onParams() {
      return this.searchForm;
    },

    // 选中数据
    handleOnSelectionChange() {},

    // 查询数据
    async onFind() {
      await this.$refs["main"]?.onFind();
    },

    // 刷新
    onRefresh() {
      this.$refs["main"].onQuery();
    },

    /**
     *  @actions:  新增班级
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/19 22:05
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleCreateClass() {
      this.$refs["edit"].open();
    },

    /**
     *  @actions:  按钮组
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/19 23:03
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleCreate(type) {
      switch (type) {
        case "batch":
          this.$refs["batchCreate"].open();
          break;
      }
    },

    /**
     *  @actions:  下载导入模板
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/19 23:46
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleExportTempalte() {
      let link = document.createElement("a");
      link.setAttribute("download", "学生导入模板.xlsx");
      link.href = "/student.xlsx"; // 你本地资源文件的存放地址
      link.click();
    },

    /**
     *  @actions:  一键升级班级
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/6 22:39
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleLitreGrade() {
      this.$confirm(
        "一键升级班级后，所有班级将提升一个年级，确定要一键升级班级吗?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        this.$cloud
          .post("/record/up")
          .then(() => {
            this.loading = false;
            this.$message.success("一键升级成功!");
            this.onRefresh();
          })
          .catch((err) => {
            this.loading = false;
            this.$message.error(err);
          });
      });
    },

    /**
     *  @actions:  显示任课老师
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/1 23:12
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    onTaechers(teachers) {
      let name = "-";
      if (teachers && teachers.length) {
        name = teachers
          .map((item) => {
            return item?.subject.name + "（" + item?.User?.username + "）";
          })
          .join(" ");
      }
      return name;
    },

    // 更新班级
    handleSchedule(row) {
      this.$refs["schedule"].open(row);
    },

    // 更新班级
    handleUpdateClass(row) {
      this.$refs["edit"].open(row);
    },

    // 删除班级
    handleDeleteClass(row) {
      this.$confirm("确定删除该班级吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$cloud
          .get("/room/delete", { id: row["id"] })
          .then((res) => {
            console.log(res);
            this.$message.success("删除成功！");
            this.onRefresh();
          })
          .catch((err) => {
            this.$message.error(err);
          });
      });
    },

    /**
     *  @actions:  导入学生
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/19 22:36
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleImportStudent(e) {
      // console.log("文件：", e);
      let file = e.file; // 文件信息
      // 错误情况判断
      if (!file) {
        return false;
      } else if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
        this.$message.error("上传格式不正确，请上传xls或者xlsx格式");
        return false;
      }
      const fileReader = new FileReader(); // 读取文件
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result; // 获取结果
          // 获取所有表的信息
          const workbook = xlsx.read(data, {
            type: "binary", // 以字符编码的方式解析
            cellDates: true, // 将excel中日期类型数据，转化为标准日期格式，而不是默认的数字格式
          });
          // 获取第一张表的表名
          const exlname = workbook.SheetNames[0];

          // 转换成json数据
          const exl = xlsx.utils.sheet_to_json(workbook.Sheets[exlname]); // 生成json表格内容
          // 打印 ws 就可以看到读取出的表格数据
          // console.log("上传后的文件", exl);
          let obj = {
            no: "学号",
            username: "姓名",
          };
          let list = exl.map((item) => {
            let data = {};
            for (const key in obj) {
              data[key] = item[obj[key]];
            }
            return data;
          });
          // console.log("list:", list);
          this.loading = true;
          this.$cloud
            .post("/student/import", {
              roomId: this.row.id,
              list: list,
            })
            .then(() => {
              this.loading = false;
              this.$message.success("导入成功!");
              this.onRefresh();
            })
            .catch((err) => {
              this.loading = false;
              this.$message.error(err);
            });
        } catch (e) {
          // console.log("error", e);
          return false;
        }
      };
      fileReader.readAsBinaryString(file);
    },
    /**
     *  @actions:  上传选择的班级
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/20 0:12
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleSelectRow(row) {
      this.row = row;
    },
  },
};
</script>
<style lang="scss" scoped></style>
