<!--
 * ======================================
 * 说明： 更新班级信息
 * 作者： Silence
 * 文件： edit.vue
 * 日期： 2023/5/12 0:23
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div>
    <Dialog
      :title="mode === 1 ? title : '【' + title + '】更新信息'"
      confirmText="保 存"
      width="860px"
      height="620px"
      v-model="visible"
      @handleConfirm="handleConfirm"
    >
      <el-form
        ref="editForm"
        :model="editForm"
        :rules="rules"
        label-width="100px"
        v-loading="loading"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="学年" prop="semester_year">
              <el-input-number
                :disabled="disabled"
                style="width: 100%"
                v-model="editForm.semester_year"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年级" prop="level">
              <el-input-number
                :disabled="disabled"
                style="width: 100%"
                :min="0"
                v-model="editForm.level"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="学期" prop="semester">
              <el-select
                :disabled="disabled"
                style="width: 100%"
                v-model="editForm.semester"
              >
                <el-option :value="1" label="第1学期"></el-option>
                <el-option :value="2" label="第2学期"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="名称" prop="name">
              <!--              <el-input v-model="editForm.name"></el-input>-->
              <el-input-number
                :disabled="disabled"
                style="width: 100%"
                :min="0"
                v-model="editForm.name"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="班主任" prop="teacher_id">
              <el-select
                filterable
                style="width: 100%"
                v-model="editForm.teacher_id"
                disabled
              >
                <el-option
                  v-for="(item, index) in teachers"
                  :key="index"
                  :value="item.id"
                  :label="item.username"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!--          <el-col :span="12">-->
          <!--            <el-form-item label="课程表" prop="schedule_id">-->
          <!--              <el-select-->
          <!--                filterable-->
          <!--                style="width: 100%"-->
          <!--                v-model="editForm.schedule_id"-->
          <!--              >-->
          <!--                <el-option-->
          <!--                  v-for="(item, index) in schedules"-->
          <!--                  :key="index"-->
          <!--                  :value="item.id"-->
          <!--                  :label="item.name"-->
          <!--                ></el-option>-->
          <!--              </el-select>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
        </el-row>
        <el-row :span="20">
          <el-col :span="24">
            <div class="text-right">
              <el-button size="mini" type="success" @click="handleAddStudent"
                >添加学生</el-button
              >
              <el-button size="mini" type="danger" @click="handleRemoveStudent"
                >移除学生</el-button
              >
            </div>
            <Table
              class="mt-2"
              :columns="stadentColumns"
              :rows="rows"
              :height="280"
              :is-page="false"
              :is-search="false"
              :is-action="false"
              @onSelectionChange="onSelectionChange"
            ></Table>
          </el-col>
        </el-row>
      </el-form>
    </Dialog>
    <Students ref="students" @conform="handleOnConform"></Students>
  </div>
</template>
<script>
import Dialog from "@/pages/components/dialog";
import Table from "@/pages/components/table";
import xlsx from "xlsx";
import { stadentColumns } from "../config.js";
import Students from "./students.vue";
export default {
  name: "",
  components: { Dialog, Table, Students },
  props: {},
  data() {
    return {
      mode: 1, // 1、新增   2、更新
      stadentColumns,
      visible: false,
      loading: false,
      title: "",
      classInfo: {},
      teachers: [],
      schedules: [],
      rows: [],
      selectRows: [],
      editForm: {
        level: 7,
      },
      rules: {
        semester_year: [
          {
            required: true,
            trigger: "blur",
            message: "请选择学年",
          },
        ],
        level: [
          {
            required: true,
            trigger: "blur",
            message: "请输入年级",
            validator: (rule, value, callback) => {
              if (value === 0 || value === "") {
                callback(new Error("请输入年级"));
              } else {
                callback();
              }
            },
          },
        ],
        name: [
          {
            required: true,
            trigger: "blur",
            message: "请输入班级名称",
          },
        ],
        semester: [
          {
            required: true,
            trigger: "blur",
            message: "请选择学期",
          },
        ],
        teacher_id: [
          {
            required: true,
            trigger: "blur",
            message: "请选择班主任",
          },
        ],
      },
    };
  },
  computed: {
    disabled() {
      return this.mode === 2;
    },
  },
  created() {},
  mounted() {},
  methods: {
    /**
     *  @actions:  打开班级更新弹窗
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/12 0:25
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    open(info) {
      this.editForm = {};
      this.rows = [];
      if (info) {
        // 修改班级
        this.mode = 2;
        this.classInfo = info;
        this.editForm = info;
        this.title =
          info.name && info.name.length > 1
            ? String(info.level) + String(info.name)
            : String(info.level) + "0" + String(info.name) + "班";
        this.getClassStudents();
      } else {
        // 新增班级
        this.mode = 1;
        this.title = "新增班级";
      }
      this.visible = true;
    },

    /**
     *  @actions:  预加载项
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/12 1:15
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async onLoading() {
      await this.getTeachers();
      await this.getSchedule();
    },

    /**
     *  @actions:  获取班级中得学生
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/14 21:04
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    getClassStudents() {
      this.loading = true;
      this.$cloud
        .get("/room/detail", {
          roomId: this.classInfo.id,
        })
        .then((res) => {
          this.rows = res;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.loading = false;
        });
    },

    /**
     *  @actions:  获取教师列表
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/12 1:06
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async getTeachers() {
      await this.$cloud
        .get("/user/list", {
          pageSize: 999,
          pageIndex: 1,
        })
        .then((res) => {
          this.teachers = res.list;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    /**
     *  @actions:  获取课程表
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/12 1:11
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async getSchedule() {
      await this.$cloud
        .get("/schedule/list", {
          pageSize: 999,
          pageIndex: 1,
        })
        .then((res) => {
          this.schedules = res.list;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    /**
     *  @actions:  选中的学生
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/15 0:28
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    onSelectionChange(data) {
      this.selectRows = data;
    },

    /**
     *  @actions:  获取学生列表
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/12 9:30
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleAddStudent() {
      this.$refs["students"].open();
    },

    /**
     *  @actions:  移除班级中的学生
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/15 0:25
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleRemoveStudent() {
      this.selectRows.map((item) => {
        this.rows.map((part, index) => {
          if (item.id === part.id) {
            this.rows.splice(index, 1);
          }
        });
      });
    },

    /**
     *  @actions:  添加班级中的学生
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/15 0:15
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleOnConform(data) {
      data.map((item) => {
        let find = this.rows.find((part) => part.id === item.id);
        if (!find) {
          this.rows.push(item);
        }
      });
    },

    /**
     *  @actions:  更新班级信息
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/12 0:24
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleConfirm() {
      // if (!this.rows.length) {
      //   this.$message.error("请配置班级中的学生");
      //   return false;
      // }
      this.$refs["editForm"].validate((vaild) => {
        if (vaild) {
          let message = "确定新增班级吗？",
            url = "/room/create";
          if (this.mode === 2) {
            (message = "确定更新班级信息吗？"), (url = "/room/edit");
          }

          this.$confirm(message, "提示", {
            type: "warning",
          }).then(() => {
            const {
              id,
              name,
              level,
              semester,
              teacher_id,
              schedule_id,
              semester_year,
            } = this.editForm;

            let params = {
              id,
              name,
              level,
              semester,
              semesterYear: semester_year,
              teacherId: teacher_id,
              scheduleId: schedule_id,
              student_ids: this.rows.length
                ? this.rows.map((item) => item.id).join(",")
                : "",
            };
            this.loading = true;
            this.$cloud
              .post(url, params)
              .then(async () => {
                this.$message.success(
                  this.mode === 1 ? "新增成功" : "更新成功！"
                );
                this.loading = false;
                this.$emit("refresh");
                if (this.mode === 2) {
                  await this.updateClassStudents();
                } else {
                  this.visible = false;
                }
              })
              .catch((err) => {
                this.$message.error(err);
                this.loading = false;
              });
          });
        }
      });
    },

    /**
     *  @actions:  更新班级中的学生
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/15 1:20
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async updateClassStudents() {
      this.loading = true;
      this.$cloud
        .post("/room/student", {
          roomId: this.editForm.id,
          studentIds: this.rows.map((item) => item.id),
        })
        .then(async () => {
          this.$emit("refresh");
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
