<!--
 * @Description:
 * @Version: 0.0.1
 * @Autor: Silence
 * @Date: 2023-05-07 19:25:50
 * @LastEditors:
 * @LastEditTime: 2023-05-07 19:25:50
-->
<template>
  <div class="w-full h-full flex">
    <Main
      ref="main"
      class="h-full"
      :columns="columns"
      :is-index="true"
      :is-selection="false"
      api="/schedule/list"
      :on-params="onParams"
      :on-result="onResult"
      @onRowClick="onRowClick"
    >
      <template #action>
        <el-input
          v-model="newSchedule"
          style="width: calc(100% - 70px)"
          placeholder="新课程表名称"
        ></el-input
        ><el-button
          type="success"
          style="margin-left: 5px"
          @click="handleCreateSchedule"
          >创建</el-button
        >
      </template>
      <template #search>
        <div class="p-3 flex pt-0">
          <el-input
            style="width: calc(100% - 70px)"
            v-model="searchForm.name"
            placeholder="按课程表名称"
          ></el-input>
          <el-button type="primary" style="margin-left: 5px" @click="onFind"
            >查询</el-button
          >
        </div>
      </template>
      <template #table-item-name="{ row }">
        <el-input
          v-if="row.id === editId"
          v-model="row['name']"
          @focus="handleFocusEditRow(row['name'])"
          @blur="handleEditRow(row)"
          @keyup.enter.native="handleEditRow(row)"
        ></el-input>
        <span v-else>{{ row.name }}</span>
      </template>
      <template #table-item-action="{ row }">
        <el-button type="text" @click="handleUpdateSchedule(row)"
          >修改</el-button
        >
        <el-button
          type="text"
          class="text-red-600"
          @click="handleDeleteSchedule(row)"
          >删除</el-button
        >
      </template>
    </Main>
    <div class="flex-1 border" style="position: relative" v-loading="loadInfo">
      <div class="text-center p-2 fa-2x font-bold">{{ scheduleItem.name }}</div>
      <div class="m-3 mt-0 border border-solid">
        <el-row class="flex flex-wrap">
          <el-col
            :span="3"
            class="text-center p-1 border-r border-solid bg-gray-100 font-bold"
            >时间</el-col
          >
          <el-col
            :span="3"
            class="text-center p-1 border-r border-solid bg-gray-100 font-bold"
            >周一</el-col
          >
          <el-col
            :span="3"
            class="text-center p-1 border-r border-solid bg-gray-100 font-bold"
            >周二</el-col
          >
          <el-col
            :span="3"
            class="text-center p-1 border-r border-solid bg-gray-100 font-bold"
            >周三</el-col
          >
          <el-col
            :span="3"
            class="text-center p-1 border-r border-solid bg-gray-100 font-bold"
            >周四</el-col
          >
          <el-col
            :span="3"
            class="text-center p-1 border-r border-solid bg-gray-100 font-bold"
            >周五</el-col
          >
          <el-col
            :span="3"
            class="text-center p-1 border-r border-solid bg-gray-100 font-bold"
            >周六</el-col
          >
          <el-col :span="3" class="text-center p-1 bg-gray-100 font-bold"
            >周日</el-col
          >
        </el-row>
        <el-row class="flex flex-wrap">
          <el-col
            :span="3"
            class="
              text-center
              p-1
              border-solid border-r border-t
              flex
              items-center
              justify-center
            "
            >上午</el-col
          >
          <el-col
            v-for="(item, index) in tables.morning"
            :span="3"
            :key="index"
          >
            <div
              :class="`
                text-center
                p-1
                h-8
                border-t border-solid
                cursor-pointer
                hover:bg-gray-100
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-indigo-500
                ${index < tables.morning.length - 1 ? 'border-r' : ''}
              `"
              v-for="(part, i) in item.list"
              :key="i"
              @click="handleSetSubject('morning', index, i, part)"
            >
              {{ part.subjectName }}
            </div>
          </el-col>
        </el-row>
        <el-row class="flex flex-wrap">
          <el-col
            :span="3"
            class="
              text-center
              p-1
              border-solid border-r border-t
              flex
              items-center
              justify-center
            "
            >下午</el-col
          >
          <el-col
            v-for="(item, index) in tables.afternoon"
            :span="3"
            :key="index"
          >
            <div
              :class="`
                text-center
                p-1
                h-8
                cursor-pointer
                border-t border-solid
                hover:bg-gray-100
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-indigo-500
                ${index < tables.morning.length - 1 ? 'border-r' : ''}
              `"
              v-for="(part, i) in item.list"
              :key="i"
              @click="handleSetSubject('afternoon', index, i, part)"
            >
              {{ part.subjectName }}
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="m-3 mt-0 flex flex-wrap">
        <div
          class="border-solid border mb-1"
          style="flex: 0 0 140px"
          v-for="(item, index) in subjects"
          :key="index"
        >
          <div class="p-1 text-center border-solid border-b">
            {{ item.subjectName }}
          </div>
          <div class="p-1">
            <el-select
              placeholder="配置任课老师"
              v-model="item.teacherId"
              class="w-full"
            >
              <el-option
                v-for="(option, index) in item.options"
                :key="index"
                :value="option.value"
                :label="option.label"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <el-button
        type="success"
        style="position: absolute; right: 0.75rem; bottom: 45px"
        @click="handleSaveSchedule"
        >保存课程表</el-button
      >
    </div>
    <Subject ref="subject" @confirm="onConfirm"></Subject>
  </div>
</template>
<script>
import Main from "@/pages/components/main/index.vue";
import Subject from "./components/subject.vue";
import { columns, searchOptions } from "./config.js";
// import tests from "./a.json";
export default {
  name: "",
  components: { Main, Subject },
  props: {},
  data() {
    return {
      columns,
      searchOptions,
      loadInfo: false,
      isClick: false, //判断课程表设置后是否保存过
      newSchedule: "",
      scheduleItem: {},
      searchForm: {},
      fieldConfig: {
        field: "",
        index: null,
        listIndex: null,
      },
      defaultTables: {
        morning: [
          {
            week: 1,
            list: [
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
            ],
          },
          {
            week: 2,
            list: [
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
            ],
          },
          {
            week: 3,
            list: [
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
            ],
          },
          {
            week: 4,
            list: [
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
            ],
          },
          {
            week: 5,
            list: [
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
            ],
          },
          {
            week: 6,
            list: [
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
            ],
          },
          {
            week: 7,
            list: [
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
            ],
          },
        ],
        afternoon: [
          {
            week: 1,
            list: [
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
            ],
          },
          {
            week: 2,
            list: [
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
            ],
          },
          {
            week: 3,
            list: [
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
            ],
          },
          {
            week: 4,
            list: [
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
            ],
          },
          {
            week: 5,
            list: [
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
            ],
          },
          {
            week: 6,
            list: [
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
            ],
          },
          {
            week: 7,
            list: [
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
              {
                subjectId: "",
                subjectName: "",
              },
            ],
          },
        ],
      },
      tables: {},
      subjects: [],

      editId: null,
      editOldName: "",
    };
  },
  watch: {},
  created() {},
  async mounted() {
    this.tables = JSON.parse(JSON.stringify(this.defaultTables));
    await this.$refs["subject"].getClassOptions();
    this.onFind();
  },
  methods: {
    // 参数
    onParams() {
      return this.searchForm;
    },

    // 查询后的结果
    onResult(data) {
      this.scheduleItem = {};
      this.onIsClick(async () => {
        if (data.list && typeof data.list == "object" && data.list.length) {
          this.scheduleItem = data.list[0];
          this.$refs["main"].onSetCurrentRow(data.list[0]);
          await this.getScheduleInfo();
        }
      });
    },

    // 查询表
    onFind() {
      this.onIsClick(async () => {
        await this.$refs["main"].onFind();
      });
    },

    // 判断课程表是否保存过
    onIsClick(callback) {
      if (!this.isClick) {
        callback();
      } else {
        this.$message.error("设置课程表后,请先保存课程表后再操作！");
      }
    },

    // 行单击数据
    onRowClick(data) {
      this.onIsClick(async () => {
        this.scheduleItem = data;
        await this.getScheduleInfo();
      });
    },

    // 获取课程表详情
    async getScheduleInfo() {
      this.loadInfo = true;
      await this.$cloud
        .get("/schedule/detail", { id: this.scheduleItem.id })
        .then(async (res) => {
          this.loadInfo = false;
          if (res.info.data) {
            try {
              let data = JSON.parse(res.info.data);
              this.tables =
                data.tables || JSON.parse(JSON.stringify(this.defaultTables));
              this.subjects = data.subjects || [];
              await this.getTeacherOptions();
            } catch (e) {
              this.subjects = [];
              this.tables = JSON.parse(JSON.stringify(this.defaultTables));
            }
          } else {
            this.subjects = [];
            this.tables = JSON.parse(JSON.stringify(this.defaultTables));
          }
        })
        .catch(() => {
          this.loadInfo = false;
        });
    },

    // 获取任课老师
    async getTeacherOptions() {
      this.loadInfo = true;
      this.$cloud
        .get("/user/list", {
          pageSize: 999,
          pageIndex: 1,
        })
        .then((res) => {
          this.loadInfo = false;
          if (res.list && typeof res.list == "object" && res.list.length) {
            this.subjects.map((item, index) => {
              let options = [];
              res.list.map((part) => {
                let find = part.subject.find(
                  (find) => find?.id === item.subjectId
                );
                if (find) {
                  options.push({
                    label: part.username,
                    value: part.id,
                  });
                }
              });
              this.$set(this.subjects[index], "options", options);
            });
          }
        })
        .catch(() => {
          this.loadInfo = false;
        });
    },

    // 新增课程表
    handleCreateSchedule() {
      if (!this.newSchedule) {
        this.$message.error("请输入新课程表名称");
        return;
      }
      this.$cloud
        .post("/schedule/create", { name: this.newSchedule })
        .then(() => {
          this.$message.success("创建成功！");
          this.newSchedule = "";
          this.onFind();
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    // 修改
    handleUpdateSchedule(row) {
      if (this.editOldName) {
        this.editOldName = "";
        this.$refs["main"].onFind();
      }
      this.editId = row.id;
    },

    // 修改课程表名称
    handleFocusEditRow(name) {
      this.editOldName = name;
    },

    // 更新课程表名称
    handleEditRow(row) {
      this.$cloud
        .post("/schedule/edit", { id: row.id, name: row.name })
        .then(() => {
          this.onFind();
          this.editOldName = "";
          this.editId = null;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    // 删除课程表
    handleDeleteSchedule(row) {
      this.$confirm("确定删除该课程表吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$cloud.get("/schedule/delete", { id: row["id"] }).then(() => {
          this.$message.success("删除成功！");
          this.onFind();
        });
      });
    },

    // 选择课程
    handleSetSubject(field, index, listIndex, data) {
      console.log("scheduleItem:", this.scheduleItem);
      this.fieldConfig = {
        field,
        index,
        listIndex,
      };
      this.$refs["subject"].open(this.fieldConfig, data["subjectId"]);
    },
    // 设置课程
    async onConfirm(data) {
      // 获取任课老师
      if (data.data.value || data.data.value === 0) {
        let res = await this.$cloud.get("/user/list_by_subject", {
          subjectId: data.data.value,
        });
        if (res && typeof res == "object" && res.length) {
          console.log("心理老师", res);
          let teachers = [];
          res.map((item) => {
            if (item.user) {
              teachers.push({
                label: item.user.username,
                value: item.user.id,
              });
            }
          });
          data.data["teachers"] = teachers;
        }
      }

      // 课程
      if (!this.subjects.length) {
        if (data.data.value || data.data.value === 0) {
          this.subjects.push({
            subjectId: data.data.value,
            subjectName: data.data.label,
            teacherId: "",
            options: data.data.teachers,
          });
        }
      } else {
        let find = this.subjects.find(
          (item) => item.subjectId === data.data.value
        );
        if (!find) {
          if (data.data.value || data.data.value === 0) {
            this.subjects.push({
              subjectId: data.data.value,
              subjectName: data.data.label,
              teacherId: "",
              options: data.data.teachers,
            });
          }
        }
      }

      // 课程表
      this.tables[data.config.field][data.config.index].list[
        data.config.listIndex
      ] = {
        subjectId: data.data.value,
        subjectName: data.data.label,
      };
      this.tables = JSON.parse(JSON.stringify(this.tables));

      this.isClick = true;
    },

    // 保存课程表
    handleSaveSchedule() {
      // 课程表配置
      let subjects = this.subjects.map((item) => {
        let obj = {
          ...item,
        };
        delete obj["options"];
        return obj;
      });

      let config = {
        tables: this.tables,
        subjects: subjects,
      };

      // 参数处理
      let arrays = [];
      this.tables.morning.map((item) => {
        item.list.map((part, index) => {
          if (part["subjectId"] || part["subjectId"] === 0) {
            let obj = {
              week: item.week,
              subjectId: part["subjectId"],
              index: index + 1,
            };
            arrays.push(obj);
          }
        });
      });
      this.tables.afternoon.map((item) => {
        item.list.map((part, index) => {
          if (part["subjectId"] || part["subjectId"] === 0) {
            let obj = {
              week: item.week,
              subjectId: part["subjectId"],
              index: index + 5,
            };
            arrays.push(obj);
          }
        });
      });
      arrays.map((item) => {
        let find = this.subjects.find(
          (part) => part.subjectId === item.subjectId
        );
        if (find) {
          item["teacherId"] = find["teacherId"];
        }
      });

      // 最终处理出来的数据
      let params = {
        id: this.scheduleItem.id,
        list: arrays,
        data: JSON.stringify(config),
      };

      this.$cloud.post("/schedule/update", params).then(() => {
        this.isClick = false;
        this.$message.success("课程表保存成功！");
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
