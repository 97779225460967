<!--
 * ======================================
 * 说明： 课程关系
 * 作者： Silence
 * 文件： schedule.vue
 * 日期： 2023/7/20 23:02
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div>
    <Dialog
      :title="'【' + title + '】课程表'"
      confirmText="保 存"
      width="800px"
      height="600px"
      v-model="visible"
      @handleConfirm="handleConfirm"
    >
      <div v-loading="loading">
        <el-button type="success" @click="addVisible = true">添加</el-button>
        <Table
          class="mt-2"
          :columns="scheduleColumns"
          :rows="rows"
          :height="380"
          :is-selection="false"
          :is-page="false"
          :is-search="false"
        >
          <template v-slot:table-item-subjectName="{ row }">
            {{ row.subject && row.subject.name ? row.subject.name : "-" }}
          </template>
          <template v-slot:table-item-teacherName="{ row }">
            {{
              row.teacher && row.teacher.username ? row.teacher.username : "-"
            }}
          </template>
          <template v-slot:table-item-action="{ row }">
            <el-button type="text" @click="handleUpdate(row)"> 修改 </el-button>
            <el-button
              type="text"
              class="text-red-600"
              @click="handleDelete(row)"
              >删除</el-button
            >
          </template>
        </Table>
      </div>
    </Dialog>
    <Dialog
      title="设置"
      v-model="addVisible"
      width="400px"
      height="260px"
      @handleConfirm="handleAdd"
    >
      <el-form :model="addForm" label-width="90px" ref="addForm" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="科目名称:" prop="subjectId">
              <el-select
                v-model="addForm.subjectId"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="item in subjectList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="任课老师:" prop="teacherId">
              <el-select
                v-model="addForm.teacherId"
                filterable
                style="width: 100%"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in teacherList"
                  :key="item.id"
                  :label="item.username"
                  :value="item.id"
                >
                </el-option> </el-select
            ></el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </Dialog>
  </div>
</template>
<script>
import Dialog from "@/pages/components/dialog";
import Table from "@/pages/components/table";
import schedule from "@/pages/schedule/index.vue";
import { scheduleColumns } from "../config.js";
export default {
  name: "",
  components: { Dialog, Table },
  props: {},
  data() {
    return {
      scheduleColumns,
      visible: false,
      loading: false,
      addVisible: false,
      title: "",
      rows: [],

      addForm: {
        subjectId: "",
        teacherId: "",
      },
      subjectList: [],
      teacherList: [],
      rules: {
        subjectId: [
          { required: true, message: "请选择科目", trigger: "change" },
        ],
        teacherId: [
          { required: true, message: "请选择任课老师", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    schedule() {
      return schedule;
    },
  },
  created() {
    this.getSubjectList();
    this.getTeacherList();
  },
  mounted() {},
  methods: {
    open(info) {
      this.visible = true;
      this.addForm.roomId = info.id;
      this.title =
        info.name && info.name.length > 1
          ? String(info.level) + String(info.name)
          : String(info.level) + "0" + String(info.name) + "班";
      this.getList(info);
    },

    /**
     *  @actions:  获取科目列表
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/20 23:43
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    getSubjectList() {
      this.loading = true;
      this.$cloud
        .post("subject/list", {
          pageSize: 9999,
          pageIndex: 1,
        })
        .then((res) => {
          this.loading = false;
          this.subjectList = [];
          if (res.total) {
            this.subjectList = res.list;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    /**
     *  @actions:  获取教师列表
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/20 23:52
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    getTeacherList() {
      this.loading = true;
      this.$cloud
        .get("user/list", { pageIndex: 1, pageSize: 9999 })
        .then((res) => {
          this.loading = false;
          this.teacherList = res.list;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    /**
     *  @actions:  添加课表
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/20 23:36
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async handleAdd() {
      if (this.addForm.id) {
        await this.$cloud.post("room/remove_teacher_subject", {
          id: this.addForm.id,
        });
      }

      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$cloud
            .post("room/create_teacher_subject", this.addForm)
            .then(() => {
              this.loading = false;
              this.addVisible = false;
              this.$message.success("添加成功");
              this.getList();
            })
            .catch((err) => {
              this.loading = false;
              this.$message.error(err);
            });
        }
      });
    },

    /**
     *  @actions:  修改课表
     *  @content:
     *  @Autor: Silence
     *  @Date: 2024/1/3 15:58
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleUpdate(row) {
      this.addForm["id"] = row.id;
      this.addForm["subjectId"] = row.subject_id;
      this.addForm["teacherId"] = row.teacher_id;

      this.addVisible = true;
    },

    /**
     *  @actions:  删除
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/20 23:30
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleDelete(row) {
      this.$confirm("确定删除该条数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$cloud
          .post("room/remove_teacher_subject", { id: row.id })
          .then(() => {
            this.loading = false;
            this.$message.success("删除成功");
            this.getList();
          })
          .catch((err) => {
            this.loading = false;
            this.$message.error(err);
          });
      });
    },
    /**
     *  @actions:  获取课表
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/20 23:21
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    getList() {
      this.loading = true;
      this.$cloud
        .post("room/teachers", {
          roomId: this.addForm.roomId,
        })
        .then((res) => {
          this.rows = res;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    /**
     *  @actions:  保存
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/20 23:04
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleConfirm() {},
  },
};
</script>
<style lang="scss" scoped></style>
