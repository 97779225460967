<!--
 * ======================================
 * 说明： 批量新增班级
 * 作者： Silence
 * 文件： batchCreate.vue
 * 日期： 2023/7/19 22:57
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <Dialog
    title="批量新增班级"
    confirmText="保 存"
    width="420px"
    height="260px"
    v-model="visible"
    @handleConfirm="handleConfirm"
  >
    <el-form
      ref="editForm"
      :model="editForm"
      :rules="rules"
      label-width="100px"
      v-loading="loading"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="年级" prop="level">
            <el-input-number
              style="width: 100%"
              :min="0"
              v-model="editForm.level"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="班级数量" prop="level">
            <el-input-number
              style="width: 100%"
              :min="0"
              v-model="editForm.count"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </Dialog>
</template>
<script>
import Dialog from "@/pages/components/dialog";
export default {
  name: "",
  components: { Dialog },
  props: {},
  data() {
    return {
      loading: false,
      visible: false,
      editForm:{},
      rules:{
        level:[{ required: true, message: "请输入年级", trigger: "blur" }],
        count:[{ required: true, message: "请输入班级数量", trigger: "blur" }],
      },
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    /**
     *  @actions:  打开窗口
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/19 23:00
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    open() {
      this.editForm = {};0
      this.visible = true;
    },

    /**
     *  @actions:  批量新增
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/19 22:59
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleConfirm() {
      this.$refs['editForm'].validate((vaild)=> {
        if ( vaild ) {
          this.$confirm( `确定批量创建${ this.editForm.level }年级${ this.editForm.count }个班级吗？` , '提示' , { type: 'warning' } ).then( () => {
            this.loading = true
            this.$cloud.post('room/add', this.editForm).then( () => {
              this.loading = false
              this.visible = false
              this.$message.success('批量创建成功！')
              this.$emit('refresh')
            }).catch(err=>{
              this.loading = false
              this.$message.error(err)
            })
          })
        }
      })
    },
  },
};
</script>
<style lang="scss" scoped></style>
