<!--
 * @Description:
 * @Version: 0.0.1
 * @Autor: Silence
 * @Date: 2023-05-07 14:53:26
 * @LastEditors:
 * @LastEditTime: 2023-05-07 14:53:26
-->
<template>
  <Dialog
    title="设置课程表科目"
    confirmText="保 存"
    width="420px"
    height="180px"
    v-model="visible"
    @handleConfirm="handleConfirm"
  >
    <el-select
      v-model="subjectId"
      placeholder="请选择"
      v-loading="loading"
      style="width: 100%"
      filterable
      clearable
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </Dialog>
</template>
<script>
import Dialog from "@/pages/components/dialog/index.vue";
export default {
  name: "",
  components: { Dialog },
  props: {},
  data() {
    return {
      visible: false,
      loading: false,
      config: {},
      subjectId: "",
      options: [],
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 打开窗口
    open(config, id) {
      this.visible = true;
      this.config = config;
      this.subjectId = id;
    },

    /**
     *  @actions:  设置课程表
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/8 20:53
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleConfirm() {
      let find = this.options.find((item) => item.value === this.subjectId);
      if (find) {
        this.$emit("confirm", { config: this.config, data: find });
      } else {
        this.$emit("confirm", {
          config: this.config,
          data: {
            value: "",
            label: "",
          },
        });
      }
      this.visible = false;
    },

    // 获取班级待选项
    async getClassOptions() {
      this.loading = true;
      await this.$cloud
        .get("/subject/list", {
          pagelndex: 1,
          pageSize: 999,
        })
        .then((res) => {
          this.loading = false;
          if (res.list && typeof res.list == "object" && res.list.length) {
            this.options = res.list.map((v) => {
              return {
                label: v.name,
                value: v.id,
              };
            });
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
